const data = [
    {
      id: 1,
      image: "https://img.icons8.com/ios/50/java-coffee-cup-logo--v1.png",
      title: "Java",
      desc: "Skilled in Java, encompassing both core and advanced concepts, with expertise in JDBC and Spring frameworks for robust and scalable application development.",
    },
    {
      id: 2,
      image: "https://img.icons8.com/ios-filled/50/google-code.png",
      title: "Web Development",
      desc: " Experienced in web development, proficient in HTML, CSS, Bootstrap, JavaScript, and React.js to create engaging and user-friendly websites and applications.",
    },
    {
      id: 3,
      image: "https://img.icons8.com/external-solidglyph-m-oki-orlando/32/external-App-Development-android-app-development-solidglyph-m-oki-orlando.png",
      title: "App Development",
      desc: "Proficient in app development, utilizing Java and XML within Android Studio, and integrating Firebase for seamless and feature-rich mobile applications.",
    },
    {
      id: 4,
      image: "https://img.icons8.com/ios-filled/50/database.png ",
      title: "DataBases",
      desc: "Experienced in working with databases, proficient in SQL and MySQL for relational databases, and Firebase for NoSQL and real-time data storage.",
    },
    {
      id: 5,
      image: "https://img.icons8.com/sf-black/64/administrative-tools.png ",
      title: "Software Tools",
      desc: "Familiar with various software platforms, including Visual Studio Code, Eclipse, IntelliJ IDEA, GitHub for version control, and LeetCode for coding challenges and skill enhancement.",
    },
    {
      id: 6,
      image: "https://img.icons8.com/ios/50/communication--v1.png",
      title: "Soft Skills",
      desc: " Possesses strong soft skills, including effective communication, analytical problem-solving abilities, and a collaborative approach to teamwork.",
    },
  ]
  export default data