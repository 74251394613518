import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import Home from './components/Home/Home'
import Features from './components/Features/Features'
import Portfolio from './components/Portfolio/Portfolio';
import Resume from './components/Resume/Resume';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Blog from './components/Blog/Blog';

const App = () => {
  return (
    <>
    <Header/>
    <Home />
    <Features />
    <Resume />
    <Portfolio />
    <Contact />
    <Footer />
    </>
  );
}

export default App;
