const ResumeApi = [
    {
        id: 1,
        category: "education",
        year: "Bachelor Of Technology (2020 - 2023) ",
        title: "Information Technology",
        desc: " Learning C, Java, SQL, DSA enhances programming skills, with practical problem-solving and effective communication vital in software development.",
        rate: "7.6/10 ",
      },

   
    {
      id: 2,
      category: "education",
      year: "Technical Education (2017 - 2020) ",
      title: "Diplamo In Computer Engineering",
      desc: "A computer engineering degree cultivates hardware and software expertise, fostering problem-solving skills for diverse tech career opportunities.",
      rate: "7.64/10",
    },
    {
        id: 3,
        category: "education",
        year: "Seconday Education (2016 - 2017)",
        title: "Secondary Education",
        desc: "10th class education shapes personal and academic growth, building vital skills for diverse career opportunities, setting the stage for a promising future.",
        rate:"9.3/10",
      },
    {
      id: 4,
      category: "experience",
      year: "Suven Consultants & Technology Pvt Ltd|  ",
      title: "Java Coding Internship",
      desc: "The training involves two projects: the Consumer Loan Assistant and the Home Inventory Management projects. Acquired a comprehension of fundamental Object-Oriented Programming (OOP) concepts, encompassing encapsulation, inheritance, event handling in Java Swings, and exception handling.",
     rate:"4Weeks",
    },
    {
      id: 5,
      category: "experience",
      year: "Suvidha Foundation Pvt. Ltd. | ",
      title: "Machine Learning Intern",
      desc: " Played an integral role in a collaborative project focusing on image object detection with 3D CNNs.Utilized effective communication skills to actively engage in team discussions and provide regular.Applied analytical skills to assist in refining the model, resulting in notable improvements in object recognition accuracy.",
      rate: "1 Month ",
    },

  ]
  
  export default ResumeApi