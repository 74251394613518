import React from "react"
import "./Home.css"
import skill1 from "../pics/skill1.png"
import skill2 from "../pics/skill2.png"
import skill4 from "../pics/skill4.png"
import mahesh from "../pics/mahesh1.png"
import { Typewriter } from "react-simple-typewriter"

const Home = () => {
  return (
    <>
      <section className='hero' id='home'>
        <div className='container f_flex top'>
          <div className='left top'>
            <h3>WELCOME TO MY WORLD</h3>
            <h1>
              Hi, I’m <span>Mahesh </span>
            </h1>
            <h2>
              a
              <span>
                <Typewriter words={[" Professional Coder.", " Java Full Stack Developer."]} loop cursor cursorStyle='|' typeSpeed={70} deleteSpeed={50} delaySpeed={1000} />
              </span>
            </h2>

            <p>
              Recent Information Technology graduate with a deep-seated passion for technology, actively pursuing an entry-level
              Software Engineer role. Equipped with robust programming, adept problem-solving, and exemplary communication
              skills, I excel in collaborative innovation. I strive to deliver substantial impact within a progressive organization.

            </p>

            <div className='hero_btn d_flex'>
              <div className='col_1'>
                <h4>FIND WITH ME</h4>
                <div className='button'>
                  <button className='btn_shadow'>
                    <a href="https://github.com/iammahesh123">
                      <i class='fa fa-github'></i></a>
                  </button>
                  <button className='btn_shadow'>
                    <a href="https://www.instagram.com/mr.kadambala">
                      <i class='fa fa-instagram'></i></a>
                  </button>
                  <button className='btn_shadow'>
                    <a href="https://www.linkedin.com/in/kadambala-mahesh-5744b1218/">
                      <i class='fa fa-linkedin'></i></a>
                  </button>
                </div>
              </div>

              {/*<div className='col_1'>
                <h4>BEST SKILL ON</h4>
                <button className='btn_shadow'>
                  <img src={skill1} alt='' />
                </button>
                <button className='btn_shadow'>
                  <img src={skill2} alt='' />
                </button>
                <button className='btn_shadow'>
                  <img src={skill4} alt='' />
                </button>
  </div>*/}

            </div>
          </div>
          {
/*
            <div className='right'>
              <div className='right_img'>
                <img src={mahesh} alt="no"/>
              </div>
            </div>
          */   }
        </div>
      </section>
    </>
  )
}

export default Home